import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-56d9f072"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  style: {
    "padding": "0.6rem 1rem 1rem"
  }
};
const _hoisted_2 = {
  class: "card"
};
const _hoisted_3 = {
  style: {
    "display": "flex"
  }
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  style: {
    "color": "rgb(102, 102, 102)",
    "font-size": "0.8rem"
  }
};
const _hoisted_6 = {
  class: "time"
};
const _hoisted_7 = {
  style: {
    "padding": "0.6rem 1rem 1rem"
  }
};
const _hoisted_8 = {
  class: "card"
};
const _hoisted_9 = {
  style: {
    "display": "flex"
  }
};
const _hoisted_10 = {
  class: "title"
};
const _hoisted_11 = {
  style: {
    "color": "rgb(102, 102, 102)",
    "font-size": "0.8rem"
  }
};
const _hoisted_12 = {
  class: "time"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");

  const _component_van_list = _resolveComponent("van-list");

  const _component_van_tab = _resolveComponent("van-tab");

  const _component_van_tabs = _resolveComponent("van-tabs");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_nav_bar, {
    title: "Message",
    "left-arrow": "",
    onClickLeft: $setup.onClickLeft
  }, null, 8, ["onClickLeft"]), _createVNode(_component_van_tabs, {
    modelValue: $setup.activeTab,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.activeTab = $event),
    onChange: $setup.switchTab
  }, {
    default: _withCtx(() => [_createVNode(_component_van_tab, {
      title: "Unread",
      name: "unread"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_list, {
        loading: $setup.loading,
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => $setup.loading = $event),
        finished: $setup.finished,
        "finished-text": "没有更多了",
        onLoad: $setup.onLoad
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, item => {
          return _openBlock(), _createElementBlock("div", {
            key: item.id
          }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
            class: "color",
            style: _normalizeStyle({
              backgroundColor: item.color || 'rgb(248, 149, 92)'
            })
          }, null, 4), _createElementVNode("div", _hoisted_4, _toDisplayString(item.title), 1)]), _createElementVNode("div", _hoisted_5, _toDisplayString(item.content), 1), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", null, _toDisplayString(item.createDate || item.time), 1)])])]);
        }), 128))]),
        _: 1
      }, 8, ["loading", "finished", "onLoad"])])]),
      _: 1
    }), _createVNode(_component_van_tab, {
      title: "All message",
      name: "all message"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createVNode(_component_van_list, {
        loading: $setup.loading,
        "onUpdate:loading": _cache[1] || (_cache[1] = $event => $setup.loading = $event),
        finished: $setup.finished,
        "finished-text": "没有更多了",
        onLoad: $setup.onLoad
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, item => {
          return _openBlock(), _createElementBlock("div", {
            key: item.id
          }, [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", {
            class: "color",
            style: _normalizeStyle({
              backgroundColor: item.color || 'rgb(248, 149, 92)'
            })
          }, null, 4), _createElementVNode("div", _hoisted_10, _toDisplayString(item.title), 1)]), _createElementVNode("div", _hoisted_11, _toDisplayString(item.content), 1), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", null, _toDisplayString(item.createDate || item.time), 1)])])]);
        }), 128))]),
        _: 1
      }, 8, ["loading", "finished", "onLoad"])])]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "onChange"])]);
}