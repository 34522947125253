import { ref, onMounted } from "vue";
import { pagereadnotice, pageappnotice } from "@/api/appNotice"; // 导入接口

import { hideLoading, showLoading } from "@/utils/utils";
export default {
  name: "message",

  setup() {
    const activeTab = ref("unread"); // 默认显示 unread Tab

    const loading = ref(false); // 控制加载状态

    const finished = ref(false); // 控制是否已加载完所有数据

    const items = ref([]); // 存储消息列表数据

    const currentPage = ref(1); // 当前页码

    const pageSize = ref(10); // 每页数据条数

    const totalItems = ref(0); // 数据总条数
    // 从本地存储中获取并解析 userInfo

    const userInfoString = localStorage.getItem("userInfo");
    const userInfo = userInfoString ? JSON.parse(userInfoString) : null; // 加载数据

    const loadData = async (tab, reset = false) => {
      showLoading("Loading..."); // 显示加载状态

      loading.value = true;

      try {
        var _response;

        let response;

        if (tab === "unread") {
          // 调用未读消息接口
          response = await pageappnotice({
            type: "2",
            current: currentPage.value,
            size: pageSize.value
          });
        } else if (tab === "all message" && userInfo !== null && userInfo !== void 0 && userInfo.loginName) {
          // 调用已读消息接口
          response = await pagereadnotice({
            loginName: userInfo.loginName,
            current: currentPage.value,
            size: pageSize.value
          });
        }

        if (((_response = response) === null || _response === void 0 ? void 0 : _response.code) === 1) {
          const newItems = response.data.records;
          totalItems.value = response.data.total;

          if (reset) {
            items.value = newItems;
          } else {
            items.value.push(...newItems);
          }

          if (items.value.length >= totalItems.value) {
            finished.value = true;
          } else {
            currentPage.value++;
          }
        } else {
          var _response2;

          console.error("获取数据失败", ((_response2 = response) === null || _response2 === void 0 ? void 0 : _response2.msg) || "未知错误");
        }
      } catch (error) {
        console.error("加载数据失败", error);
      }

      hideLoading(); // 隐藏加载状态

      loading.value = false;
    }; // 切换 Tab 时调用


    const switchTab = tab => {
      currentPage.value = 1;
      items.value = [];
      finished.value = false;
      loadData(tab, true); // 切换 Tab 时加载新数据
    }; // 加载更多数据


    const onLoad = () => {
      loadData(activeTab.value); // 根据当前激活的 Tab 加载数据
    }; // 组件挂载时，默认加载未读消息


    onMounted(() => {
      switchTab("unread");
    });
    return {
      activeTab,
      onClickLeft: () => history.back(),
      onLoad,
      items,
      loading,
      finished,
      switchTab
    };
  }

};